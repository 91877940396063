@font-face {
  font-family: 'Roxborough-CF';
  src: url('./fonts/Roxborough-CF.woff2') format("woff2"),
       url('./fonts/Roxborough-CF.woff') format("woff");
}
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p{margin-top: 0; font-family: "Mulish", sans-serif;}
body{ font-family: "Mulish", sans-serif; color: #212022;}
.container{max-width: 1130px; margin: 0 auto; padding: 0 15px;}
.btn{display: inline-flex; min-width:250px; text-align: center; justify-content: center; align-items: center; -webkit-appearance:none; -moz-appearance:none; appearance:none; background: transparent;  border: 1px solid transparent; border-radius: 50px; position: relative;   font-family: "Mulish", sans-serif; font-weight: 700;  -webkit-transition: 0.5s ease all; -o-transition: 0.5s ease all; transition: 0.5s ease all;  letter-spacing: normal;  padding: 22px;    font-size: 18px;line-height: 1; font-weight: 500;  letter-spacing:normal;  text-align: center;  text-transform: inherit;   text-decoration: none; cursor: pointer;}
.btn-primary{
  background: #214424; border-color: #214424; color: #fff;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    &:hover{background: #214424; color: #fff;}
  }
}
// .foo{height: 127px;}
// .sc{width: 1200px;}
// .homepage-Header-View{width: 100%; height: 100%; position: absolute; top: 0px; left: 0px; background-image: url('//static.showit.co/200/odxrFyxiQLmzmd4dJX0Kgg/125909/website_ideation_20.png');}
// .foo{width: 100%; height: 100%; position: absolute; top: 0px; left: 0px; background-image: url('//static.showit.co/200/odxrFyxiQLmzmd4dJX0Kgg/125909/website_ideation_20.png');}
// .sie-homepage-header_view-2_8, .se{transform: scale(1, 1) translate(76.5px, 0px);}




.btn-primary:hover{background: #fff; color: #214424;}
.img-fluid{max-width: 100%; height: auto;}
.btn-primary-outline{border: 1px solid  #214424; color: #214424; letter-spacing: 0.72px;}
.btn-primary-outline:hover{color: #fff; background: #214424;}
.btn-white{background: #fff; color: #214424; font-weight: 700;}

.calculator-wrap{padding: 0 0 85px;}
.steps{display: none; padding-top: 37px;}
.steps.active{display: block;}
.step-1{padding-top: 95px; }
.step-11{padding-top: 35px;}
.step-head{text-align: center; max-width: 1010px; margin-left: auto; margin-right: auto;}
.step-head h2{font-size: 48px;  color: #000; font-family: 'Roxborough-CF'; margin-bottom: 23px;}
.step-head p{font-size: 18px;}
.step-1 .btn{margin-top: 16px;}
.step-1 .step-head{margin-bottom: 66px; padding-left: 16px; padding-right: 16px;}
.step-1 img{max-width: 767px; width: 100%; display: block; margin: 0 auto; border-radius: 7px;}
.step-2{padding-top: 95px; text-align: center;}
.step2-container{max-width: 741px; margin-left: auto; margin-right: auto;}
.input-form{margin-bottom: 50px;}
.input-form h3 u{text-underline-position: under;}
.input-form h3{font-size: 40px; text-align: center; margin-bottom: 30px;  font-family: 'Roxborough-CF';}
.input-form .form-control{width: 100%; border: 1px solid #212022; height: 64px; padding: 5px 20px; color: #636363; font-size: 16px;font-family: "Mulish", sans-serif;}
.input-form .form-control::placeholder{font-family: "Mulish", sans-serif; color: #636363;}
.input-form .form-control:focus{outline: none;}
.step-9 , .step-11 {padding-bottom:50px !important;}

.back-step{display: inline-flex; gap: 7px; font-size: 16px; color: #212022; text-decoration: none; text-transform: capitalize;}
.name-response{text-align: center; padding-top: 39px; max-width: 960px; margin-left: auto; margin-right: auto;}
.name-response h3{font-size: 40px; font-family: 'Roxborough-CF'; text-transform: capitalize; color: #214424; line-height: 160%; margin-bottom: 22px;}
.name-response h5{font-size: 24px; font-weight: 600; font-family: 'Roxborough-CF'; color: #212022; margin-bottom: 54px;}
.name-response p{font-size: 18px; line-height: 160%; color: #212022;}
.name-response .btn{margin-top: 28px;}

.email-step{text-align: center; padding-top: 39px; max-width: 799px;}
.email-step .max-76{max-width: 740px; margin-left: auto; margin-right: auto;}
.email-step  .input-form p{text-align: left;}
.input-form p{margin-top: 15px;}
.input-form p a{text-decoration: underline; color: #212022;}

.team-expenses{padding-top: 30px; text-align: center; max-width: 1080px; margin-left: auto; margin-right: auto;}
.team-expenses h2{color:  #214424;text-align: center; font-family: "Roxborough-CF";font-size: 48px;font-style: normal;font-weight: 700; line-height: normal; margin-bottom: 60px; }
.team-expenses .h2{color: #212022;font-family: "Roxborough-CF";font-size: 40px; font-style: normal; font-weight: 700;line-height: 120%; margin-bottom: 32px; }
.team-expenses p{color: #212022;font-size: 18px;font-style: normal;font-weight: 400; line-height: 160%;}
.team-expenses p a{color:  #ED8A8C; text-decoration: underline;}
.team-expenses p a div{text-decoration: underline;}

.team-expenses .input-form{margin-top: 65px; }
.team-expenses .input-form h3{font-size: 32px;}
.team-expenses .input-form .form-control{max-width: 740px; margin-left: auto;margin-right: auto;}

.team-expenses.monthly-investment .input-form{margin-top: 48px;}

.team-expenses.monthly-investment .h2{margin-bottom: 32px;}

.btn-group{display: flex; gap: 48px; justify-content: center;}
.btn-group .btn{min-width: 138px;}
.show-767{display: none !important;}
.monthly-investment{max-width: 1080px; margin-left: auto; margin-right: auto;}

.final-video{max-width: 100%; margin: 0 auto; padding-top: 45px; text-align: center;}
.final-video h3{color: #212022;font-family: "Roxborough-CF";font-size: 40px; font-style: normal; font-weight: 700;line-height: 120%; margin-bottom: 48px;}
.video-box{margin-bottom: 68px; background: rgba(244, 185, 197, 0.46); padding: 8px; box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.09);}
.video-box video{min-height:385px;}
.final-video h3[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
.final-video .video-box{max-width: 704px; margin-left: auto;margin-right: auto; margin-bottom: 28px;}
.final-video .video-box+button{margin-bottom:34px;}

.result-screen1{background: url(img/result-bg.svg) no-repeat center; background-size: cover; min-height: 80vh;  padding-top: 35px;}
.result-screen1 h2{color: #212022;font-family: "Roxborough-CF";font-size: 40px; text-align: center; margin-top: 35px; font-style: normal; font-weight: 700;line-height: 120%; margin-bottom: 64px;}
.result-screen1 h3{color: #214424;font-family: "Roxborough-CF";font-size: 48px; text-align: center; font-style: normal; font-weight: 700;line-height:inherit; margin-bottom:32px;}

.result-felling .step-container{max-width: 960px; padding-top: 35px; text-align: center; margin: 0 auto;}
.result-felling h2{color: #212022;font-family: "Roxborough-CF";font-size: 40px; font-style: normal; font-weight: 700;line-height: 120%; margin-bottom: 48px;}
.result-felling .v-box{background: #FFFBFC;box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.04); display: flex;padding: 24px 44px 48px 44px;flex-direction: column;align-items: center; gap: 32px; align-self: stretch;}
.result-felling .v-box p{color:  #212022;text-align: center;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal; margin: 0;}
.result-felling .v-box p span{color: #BB0707;}
.result-felling .v-box video{max-width: 688px; margin: 0 auto; width: 100%;}
.result-felling .custom-radio-wrap{margin: 48px auto; background:  #FFFBFC; box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.04); display: flex; padding: 24px 44px 48px 44px;flex-direction: column;align-items: center;gap: 32px;align-self: stretch;}
.result-felling  .custom-radio-wrap h5{color:  #212022;text-align: center; margin: 0; font-family: "Roxborough-CF";font-size: 24px; font-style: normal; font-weight: 700; line-height: normal;}
.custom-radio-wrap .d-flex {
  display: flex;
  justify-content: center;
  gap: 44px;
}
.email-step .input-form h3 {

  margin-bottom: 48px;

}
.custom-radio [type="radio"]:disabled + label{
  cursor: not-allowed !important;
}
.custom-radio [type="radio"]:checked,
.custom-radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.custom-radio [type="radio"]:checked + label,
.custom-radio [type="radio"]:not(:checked) + label
{position: relative; padding-left: 48px; cursor: pointer;font-size: 24px; line-height: 160%; display: inline-block;color: #212022; font-family: 'Mulish', sans-serif;}
.custom-radio [type="radio"]:checked + label:before,
.custom-radio [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    border: 1px solid  #ED8A8C;
    box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
    border-radius: 100%;
    background: #fff;
    font-family: 'Mulish', sans-serif;
}
.custom-radio [type="radio"]:checked + label:after,
.custom-radio [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 22px;
    height: 22px;
    background:  #ED8A8C;;
    position: absolute;
    top: 9px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-family: 'Mulish', sans-serif;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.text-center{text-align: center;}

.result-banner-wrap{background: #FFFBFC; position: relative; overflow: hidden; padding-top: 30px; padding-bottom: 97px;}
.result-banner-wrap::before{content: ""; width: 467px; height: 467px; display: block; position: absolute; top: 0; right: -177px; flex-shrink: 0; border-radius: 467px;opacity: 0.3;background: var(--Linear, linear-gradient(180deg, #EFC56A 0%, #FE7062 100%));;filter: blur(50px);}
.result-banner-wrap::after{content: ""; width: 467px; height: 467px; display: block; position: absolute; bottom: -90px; right: 267px; flex-shrink: 0; border-radius: 467px;opacity: 0.4; background:  #F4B9C5; filter: blur(50px);}
.result-banner-wrap .revenu{border-radius: 6px; display: inline-block; background:  #212022;box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.26); padding: 8px; font-size: 18px;font-style: normal;font-weight: 400;line-height: 160%; color: #fff;}
.result-banner-wrap .container{position: relative; z-index: 2;}
.result-banner-row { display: flex;justify-content: space-between; align-items: center; margin-top: 61px;}
.result-banner-heading h1 { color: #214424;font-family: "Roxborough-CF";font-size: 86px; font-style: normal; font-weight: 700;line-height: 120%; margin: 0;}
.result-banner-content { width: 50.5%; border-radius: 16px;border: 1px solid #FFF;background: rgba(255, 255, 255, 0.38); box-shadow: 0px 12px 23px 0px rgba(187, 108, 124, 0.08); backdrop-filter: blur(2px);padding: 32px; text-align: center;}
.result-banner-heading {width: 49%;}
.result-banner-content p {color: #212022;text-align: center;font-size: 24px;font-style: normal;font-weight: 400;line-height: 160%;margin-bottom: 44px; font-family: "Mulish", sans-serif;}
.result-banner-content h3 { color: #212022; text-align: center;font-family: "Roxborough-CF";font-size: 38px;font-style: normal;font-weight: 700;line-height: 160%;margin: 0;}
.result-banner-content h3~h3 {margin-bottom: 48px;}
.result-banner-content p:last-child {margin: 0;}
.result-banner-wrap .revenu p{margin: 0;}
.money-calc-wrap{padding: 64px 0; overflow: hidden; background:  #FFF; box-shadow: 0px -5px 23px 0px rgba(0, 0, 0, 0.10); /*box-shadow: 0px 12px 4px 0px rgba(0, 0, 0, 0.25);*/ position: relative;}
.money-calc-wrap::before{content: ""; width: 646.87px; position: absolute; left: 0; bottom: 0;  height: 633.397px; transform: rotate(-60deg); border-radius: 646.87px; opacity: 0.2;background: linear-gradient(181deg, rgba(244, 185, 197, 0.26) -25.79%, #CC667B 99.13%); filter: blur(100px);}
.money-calc-head {text-align: center; max-width: 769px; margin: 0 auto 37px;}
.money-calc-wrap .container{position: relative; z-index: 2;}
.money-calc-head h2{color: #214424;font-family: "Roxborough-CF"; margin-bottom: 8px; font-size: 40px; font-style: normal; font-weight: 700; line-height: 130%;}
.money-calc-head p{color:  #212022;font-size: 18px;margin: 0; font-style: normal;font-weight: 400;line-height: 160%;}
.money-calc-row{border-radius: 16px; max-width: 809px; padding: 48px; margin: 0 auto;border-radius: 16px;
  border: 1px solid #FBE1E6;
  
  background: rgba(255, 251, 252, 0.79);
  
  box-shadow: 0px 12px 23px 0px rgba(187, 108, 124, 0.08);
  backdrop-filter: blur(3.5px)}
.money-calc-head-row {border-radius: 15px; gap:10px;background: #FBE1E6; padding:  10px 16px; display: flex; margin-bottom: 12px;}

.fin-need-dollar {width: 206px;text-align: center;}
.money-calc-head-row p { margin: 0;text-align: center; font-size: 18px; font-style: normal;font-weight: 700;line-height: 160%;}
.fin-need-equal{min-width: 30px;}
.fin-need-pers{width: 172px;}
.fin-need {width: 39%;}
.fin-need-equal img{display: block; margin: 0 auto;}
.money-calc-head-row .fin-need p{max-width: 160px;}
.money-calc-fin-row{display: flex; align-items: center; padding:  10px 16px; border-radius: 15px;border: 1px solid #5E5E5E; margin-bottom: 8px;}
.money-calc-fin-row p{color: #212022;text-align: center;font-size: 16px;font-style: normal;font-weight: 400;line-height: 160%; /* 25.6px */ margin: 0;}
.money-calc-fin-row .fin-need p{text-align: left; display: flex; align-items: center; gap: 16px;}
.money-calc-fin-row .fin-need p span{cursor: pointer;}

.money-calc-fin-row.total {border-radius: 15px; border: 1px solid #5E5E5E;background: #214424;}
.money-calc-fin-row.total p {color: #fff;font-weight: 700; font-size: 18px;}
.money-calc-fin-row.total .fin-need-equal img{filter: brightness(0) invert(1);}

.rich-move-wrap { background: #FFF; padding-top: 64px;}
.rich-move-head p {font-size: 18px;font-style: normal;font-weight: 700;line-height: 160%;color: #212022;margin: 0;}
.rich-move-head h2 { color:  #212022;font-family: "Roxborough-CF";font-size: 48px; font-style: normal;font-weight: 700;line-height: 140%; /* 67.2px */ text-transform: uppercase;}
.rich-move-head h2 span{display: block; color: #ED8A8C;}
.rich-move-wrap h3{max-width: 645px; margin-left: auto; color: #212022;font-size: 30px;font-style: normal; font-weight: 400; line-height: 160%;}
.rich-move-wrap h3 strong{font-weight: 1000;}
.rich-move-wrap h3:before { content: "";width: 336px;height: 7px;background: #214424;display: block;margin-bottom: 25px; margin-left: -26px;}
.rich-move-wrap h3:after {
  content: ""; width: 336px; height: 7px;background: #214424;display: block;margin-top: 25px;margin-left: -46%;}
.introducing-box {max-width: 1002px; margin: 70px auto 60px;border-radius: 16px;border: 1px solid #FFF;background: #214424;box-shadow: 0px 12px 41px 0px rgba(38, 15, 20, 0.09);backdrop-filter: blur(2px); padding:50px 32px; text-align: center;}
.introducing-box h2 { color: #fff; text-align: center; font-family: "Roxborough-CF"; font-size: 72px; font-style: normal; font-weight: 700;line-height: 120%; /* 86.4px */margin-bottom: 24px;}
.introducing-box h4 { font-weight:400; color: #FBCDEE;font-size: 24px; font-style: normal; line-height: 140%; max-width: 492px; margin: 0 auto 24px;}
.introducing-box p {color: #FFF;font-size: 24px;font-style: normal;font-weight: 700;line-height: 140%;margin-bottom: 24px;}
.team-expenses p a div {
  display: inline-block; cursor: pointer;
}
.modal-wrapper{
  overflow: scroll;
  background: rgba(0, 0, 0, 0.56);
  display: flex !important;
  align-items: center;

  backdrop-filter: blur(3.5px);}
  .video-modal{ max-width: 1800px; width: 90%; padding: 77px 0; margin: 0 auto; display: block;
     position: relative;}

    .video-modal .container{
        background: #FFF;  box-shadow: 0px 15px 27px 0px rgba(0, 0, 0, 0.25);
        max-width:898px;
        padding:77px 10px;
    }


    .video-modal video {
      max-width: 704px; 
      width: 100%;
      min-height:403px;
      background: rgba(244, 185, 197, 0.46);
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.09);
      padding: 8px;
  }
  .video-modal .close-icon {
    position: absolute;
    right: 32px;
    top: 20px;
}

.copyright{text-align: center; margin-top: 20px; margin-bottom:20px;}

.copyright p{
  text-align: center;
  font-size: 18px; font-family: "Mulish", sans-serif; 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px; margin: 0;
  }

.here_how_wrap{padding-top: 50px; padding-bottom: 22px;}

.here_how_row {

  display: flex;

  flex-direction: row-reverse;

  align-items: center;

  justify-content: space-between;

}

.here_how_wrap h2{text-align: center; color:  #ED8A8C; margin-bottom: 16px; font-family: "Roxborough-CF"; font-size: 48px; font-style: normal; font-weight: 700; line-height: 140%;text-transform: uppercase;}

.here_how_wrap video{max-width: 631px; margin: 0 auto; display: block; border-radius: 7px; overflow: hidden;}

.here_how_wrap .show-m{display: none !important;}



.tooltip {

  cursor: pointer;

  position: relative;

  display: inline-block;

}

.money-calc-fin-row .fin-need p span img{display: block;}

.tooltip .tooltiptext {

  width: 214px;

  padding: 17px 11px 17px 12px;

  border-radius: 13px;

  border: 1px solid #FFD1DB;

  background: rgba(255, 255, 255, 0.88);

  box-shadow: 4px 9px 19px 0px rgba(0, 0, 0, 0.13);

  backdrop-filter: blur(3px);

  color:  #212022;

text-align: center;

font-family: "Mulish", sans-serif;

font-size: 14px;

font-style: normal;

font-weight: 400;

line-height: 160%; 

  transition: opacity 0.5s, visibility 0.5s;

  opacity: 0;

  visibility: hidden;

  pointer-events: none;

  position: absolute;

  z-index: 1;

  bottom: 100%;



}



.tooltip:hover .tooltiptext {

  pointer-events: auto;

  opacity: 1;

  visibility: visible;

}
.totalTooltip img{
    filter:invert(1);
}
.sliderContainer{width:100%; margin:10px auto; padding:63px 0 106px 0; background-color: #fff; box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.06);}
.sliderContainer .item{text-align:center; padding:30px;}
.sliderContainer blockquote{
    color: #212022;
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin:0 0 25px 0;
    line-height: 26px;
}
.sliderContainer .item .slideInner{opacity:0.35}
.sliderContainer .item .slideInner{position:relative; padding:70px 70px 140px 70px; background-color: #fff; border-radius:20px; text-align: left; transition: 0.2s ease;}
.sliderContainer .item .slideInner svg{top:36px; right:20px; position: absolute;}
.sliderContainer .slick-active.slick-current .slideInner{background-color: #f9eaed; opacity: 1;}
.slideInner cite{
    font-style:normal;
}
.slideInner .authorName{
    color: #212022;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom:8px;
    line-height: normal;
    text-transform: uppercase;
}
.slideInner .authorPost{color: #808080;
    font-family: "Mulish";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:0;
}
.sliderContainer .slick-next{z-index:3; position: absolute; width: 40px; height: 40px;  right:42%; left:auto; top:auto;     transform: translateX(-50%); bottom:-33px;  transform:none; top:auto; opacity:1;}
.sliderContainer .slick-prev{z-index:3; position: absolute; width: 40px; height: 40px; right:auto; left:42%; top:auto;     transform: translateX(-50%); bottom:-33px;  opacity: 1; transform:none;}
.sliderContainer .slick-dots li{margin:0 10px;}
.sliderContainer .slick-arrow::before{display: none;}
.sliderContainer .slick-arrow.slick-next::after{ width: 40px; content: ""; display: block; opacity: 1; height: 40px; background: url(../src/img/Next.svg) no-repeat center; background-size: cover;}
.sliderContainer .slick-arrow.slick-prev::after{width: 40px; content: ""; display: block; opacity: 1; height: 40px;  background: url(../src/img/Previous.svg) no-repeat center; background-size: cover; background-size: cover;}
.sliderContainer .slick-dots li button{
    width:15px; 
    height:15px;
    background-color: #eaeaea;
    border-radius:20px;
    padding:0;
}
.sliderContainer .slick-dots li button:before{display:none;}
.sliderContainer .slick-dots li.slick-active button{background-color:#ED8A8C;}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    max-width: 229px;
    left: 50%;
    transform: translateX(-50%);
}
/* New Design css*/
.welcome-wrap{padding: 0 16px;}
.welcome_row {max-width: 1080px;margin: 0 auto;display: flex;flex-wrap: wrap;justify-content: space-between; padding-bottom: 37px; border-bottom: 1px solid #000;}

.welcome_content { width: 47%;}
.welcome_img {width: 47%;}
.welcome_img img { width: 100%;display: block;}
.welcome_content p{color:  #212022; font-family: "Mulish", sans-serif; margin-bottom: 34px; font-size: 18px; font-style: normal;font-weight: 400;line-height: normal;}
.welcome_content p span{color:  #214424; font-weight: 900;}
.welcome_content h3{color: #212022; margin-bottom: 20px; text-align: center;font-family: "Roxborough-CF"; font-size: 40px; font-style: normal;font-weight: 700;line-height: normal;}
.welcome_content img{max-width: 51px; margin: 0 auto 32px;}
.step-one-start{max-width: 1080px; margin: 0 auto; padding: 48px 16px 64px; text-align: center;}
.step-one-start p{max-width: 1016px; margin: 0 auto 50px; color: #212022;text-align: center;font-family: "Mulish", sans-serif; font-size: 24px;font-style: normal;font-weight: 400;line-height: normal;}
.step-1 .btn{font-weight: 700; font-size: 16px; letter-spacing: 0.64px;}
// .result-felling video::-webkit-media-controls-timeline, .step-9 video::-webkit-media-controls-timeline {
//     display: none;
// }
.wondering_box{max-width: 1080px; margin: 0 auto;  padding: 48px 32px; border-radius: 9px;background: rgba(255, 255, 255, 0.59);box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);backdrop-filter: blur(20px);}
.wondering_wrap{padding-bottom: 64px; padding-left: 16px; padding-right: 16px; position: relative;}
.wondering_wrap::before{width: 646.87px; display: block; position: absolute; left: -14%; top: 0; height: 633.397px;transform: rotate(-60deg);flex-shrink: 0; content: ""; border-radius: 646.87px;opacity: 0.2;background: linear-gradient(181deg, rgba(244, 185, 197, 0.26) -25.79%, #CC667B 99.13%);filter: blur(75px);}
.wondring_head{text-align: center; padding: 0 10px; margin-bottom: 60px;}
.wondring_head h2{color: #000;margin-bottom: 32px; align-self: stretch; font-family: "Roxborough-CF";font-size: 40px; font-style: normal; font-weight: 700;line-height: normal;}
.wondring_head h3{color: #ED8A8C;font-family: "Roxborough-CF";font-size: 40px;font-style: normal;font-weight: 700;line-height: normal; margin: 0;}
.wondering_number{display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;}
.wondering_number_head{width: 35%;}
.wondering_number_head h3{color:  #212022;font-family: "Mulish", sans-serif; margin-bottom: 16px; font-size: 32px;font-style: normal;font-weight: 900;line-height: 160%;}
.wondering_number_head  p{color: #212022;font-family: "Mulish", sans-serif;font-size: 20px; font-style: normal;font-weight: 500;line-height: 160%; margin: 0;}

.wondering_number_row {width: 65%;display: flex;flex-wrap: wrap; row-gap: 32px;justify-content: space-between;}

.wondering_number_box {width: 48%; padding: 24px; background:  #FFFBFC;box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.09);}
.wondering_number_box h4 { color: #000;text-align: center;font-family: "Roxborough-CF"; margin-bottom: 24px; font-size: 18px;font-style: normal;line-height: normal;}
.wondering_number_box p{color:  #212022; margin-bottom: 10px; font-size: 18px;font-style: normal;font-weight: 400;line-height: normal;}
.wondering_footer{padding-top: 64px; text-align: center;}
.wondering_footer p{margin: 0; color: #000;font-size: 18px;font-style: normal;font-weight: 400;line-height: normal;}

.Chances_wrap{background: rgba(255, 255, 255, 0.07);box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);backdrop-filter: blur(9.5px); padding: 64px 16px; margin-bottom: 16px; }
.Chances_row{max-width: 1080px; margin: 0 auto; row-gap: 48px; display: flex; justify-content: space-between;}
.single_chances{width: 47%; text-align: center;}
.single_chances h2{color:  #EFC56A; font-family: "Roxborough-CF";font-size: 40px;font-style: normal;font-weight: 700;line-height: normal; margin-bottom: 24px;}
.single_chances h2::before{content: ""; display: block; margin: 0 auto 15px; max-width: 220px; height: 8px; background: currentColor;}
.single_chances p{color:  #212022;text-align: center;font-family: "Mulish", sans-serif;font-size: 18px;font-style: normal;font-weight: 400;line-height: normal;}
.single_chances p span{color: #214424; font-weight: 900;}
.single_chances.lastly h2{color: #ED8A8C;}
.Chances_footer{max-width: 1080px; margin: 44px auto 0; text-align: center; }
.Chances_footer h2{color: #000;font-family: "Roxborough-CF"; margin-bottom: 12px;  font-size: 40px;font-style: normal;font-weight: 700;line-height: normal;}
.Chances_footer h2 span{color: #ED8A8C;}
.step_one_gathering{padding: 61px 16px; overflow: hidden; text-align: center; position: relative;}
.step_one_gathering::before{content: ""; position: absolute; right: 0; top: 0; width: 646.87px;height: 633.397px;transform: rotate(-60deg);flex-shrink: 0; border-radius: 646.87px;opacity: 0.1;background: linear-gradient(181deg, rgba(239, 197, 106, 0.13) -25.79%, #EFC56A 99.13%);
filter: blur(75px); }
.step_one_gathering h6{color: #212022; font-family: "Mulish", sans-serif;font-size: 16px;font-style: normal; margin-bottom: 32px; font-weight: 700;line-height: normal;}
.step_one_gathering h2{color: #212022; margin-bottom: 32px; font-family: "Roxborough-CF";font-size: 40px;font-style: normal;font-weight: 700;line-height: normal;}
.step_one_gathering p{color:  #212022;text-align: center;font-size: 18px;font-style: normal; font-weight: 400; line-height: normal; max-width: 803px; margin: 0 auto 16px;}
.step_one_gathering p span{color: #214424; font-weight: 900;}
.step_one_gathering p a{color: #ED8A8C; text-decoration: underline; font-weight: 1000;}
 .mobile-show{display: none  !important;}


.team-expenses-row{ border-top: 1px solid #000; margin-bottom: 48px; border-bottom: 1px solid #000; display: grid;grid-template-columns: repeat(3, 1fr);gap: 48px; padding: 24px 0; }
.team-expenses-row>ul{ margin: 0; padding-left: 25px;}
.team-expenses-row>ul li{ text-align: left; color:  #212022;font-family: "Mulish", sans-serif; margin-bottom: 0; font-size: 18px;font-style: normal;font-weight: 500;line-height: 160%;}
.team-expenses-row>ul li a{color: #ED8A8C; text-decoration: none;}
.team-expenses-row>ul>li~li{margin-top: 30px;}
.team-expenses-row>ul li ul{margin-top: 30px; list-style: disc; padding-left: 26px;}
.team-expenses-row>ul li p{font-weight: 700; padding-left: 26px; margin-top: 14px; margin-bottom: 0; color:  #212022;font-family: "Mulish", sans-serif;font-size: 18px;font-style: normal;}
.team-expenses-notes{padding: 48px 0;}
.team-expenses-notes h2{color: #212022; margin-bottom: 32px; text-align: center;font-size: 40px; font-style: normal;font-weight: 700;line-height: 120%;}
.team-expenses-notes-row{display: grid;grid-template-columns: repeat(2, 1fr);gap: 64px;}
.team-expenses-notes-row.full_width{display: grid;grid-template-columns: repeat(1, 1fr);gap: 64px;}
.team-expenses-notes-row.full_width p{ text-align: center;}
.team-expenses-notes-row .item{background:  #FFFBFC;box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.09); padding: 24px;}
.team-expenses-notes-row p{color:  #212022; text-align: left; font-size: 18px; margin: 0; font-style: normal;font-weight: 500;line-height: 160%;}
.team-expenses-notes-row p b{font-weight: 900;}
.team-expenses .input-form p{text-align: left; max-width: 740px; margin-top: 8px; margin-left: auto; margin-right: auto; font-size: 16px;}
.team-expenses .input-form p a{text-decoration: underline; color: #212022;}
.team-expenses .input-form{margin-bottom: 48px;}
.step-6 .team-expenses-row>ul li ul{margin-top: 0;}
.step-6 .team-expenses-row{margin-top: 30px;}

.step-7 .team-expenses-row{margin-top: 32px;}
.step-7 .team-expenses-row{grid-template-columns: repeat(2, 1fr);}
.team-expenses-row > ul li ul{margin-top: 0;}
.step-7 .team-expenses .input-form h3{max-width: 939px; margin-left: auto; margin-right: auto;}
.step-8 .team-expenses-notes{border-top: 1px solid #000;}
.step-8 .team-expenses-notes-row { display: block;}
.step-8 .team-expenses-notes-row .item{max-width: 952px; margin: 0 auto; width: 100%;} 

.step-9 .number_keys{padding: 64px 0; border-top: 1px solid #000; margin-bottom: 48px; border-bottom: 1px solid #000;}
.step-9 .number_keys p{color:  #212022; max-width: 1019px; margin: 0 auto; text-align: center;font-size: 24px;font-style: normal;font-weight: 400;line-height: 160%;}
.step-9 .number_keys_box{max-width: 850px; width: 100%;  background: rgba(255, 255, 255, 0.07); padding: 48px 15px; box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);backdrop-filter: blur(9.5px); margin: 32px auto;}
.number_keys_box h4{color:  #212022; text-align: center; margin-bottom: 32px; font-family: "Mulish", sans-serif; font-size: 24px;font-style: normal;font-weight: 700;line-height: 120%;}
.number_keys_box li{color:  #212022;font-family: "Mulish", sans-serif;font-size: 18px;font-style: normal;font-weight: 600;line-height: 160%; }
.number_keys_box ol {width: fit-content; margin: 0 auto; text-align: left;}
.result_con{background: #FFF;box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.04); padding: 24px 0; text-align: center; margin-bottom: 48px; }
.result_con p{color: #212022;text-align: center;font-size: 20px;font-style: normal;font-weight: 400;line-height: 160%;}
.result_con p span{display: block; font-size: 25px; font-weight: 700;}
.result-screen1 .btn{width: 254px; margin: 0 auto; display: block;}
.result-felling h3{color: #214424;text-align: center;font-family: "Roxborough-CF";font-size: 48px;margin-bottom: 48px; font-style: normal;font-weight: 700;line-height: normal;}
.money-calc-footer{text-align: center; margin-top: 48px;}
.money-calc-footer p{color:  #212022;font-size: 18px;font-style: normal; margin: 0; font-weight: 400;line-height: 160%;}
.money-calc-footer p a{text-decoration: underline; color: #212022;}

.back-step {position: relative; padding-top:5px;}
// .back-step:hover .back-tooltip{opacity: 1; pointer-events: inherit;}
.tool-back-wrapper{position: relative;}
.back-tooltip {max-width: 306px; opacity: 1; transition: 0.5s ease all; border-radius: 13px; border: 1px solid #FFD1DB;background: rgba(255, 255, 255, 0.88);box-shadow: 4px 9px 19px 0px rgba(0, 0, 0, 0.13);backdrop-filter: blur(3px); padding: 24px;position: absolute;left: 91px;top: -33px;}

.back-tooltip .btn {max-width: 94px; min-width: 94px;padding: 13px;font-size: 16px;font-weight: 700;letter-spacing: 0.64px;}

.back-tooltip p {color: #212022;font-size: 16px;font-style: normal; font-weight: 400;line-height: 160%;margin-bottom: 8px;}
.back-tooltip::before {
  width: 25px;
  height: 25px;
  content: "";
  background: url(../src/img/back-svg-b.svg) no-repeat center; background-size: cover;
  display: block;
  position: absolute;
  left: -25px;
  top: 36px;
}
.header_wrap{background-color: rgba(245, 243, 243, 1); padding: 5px 0; position: relative;}
.header_row{    display: flex;align-items: center;justify-content: space-between;}
.header_wrap .container {
  max-width: 1260px;
}
.header_row .header_item {display: flex;}
.header_row .header_item.header-fill { flex: 1 0 0; align-items: center;}
.header_row .header-logo {justify-content: center;}
.header_row .header-logo a img {max-width: 118px;margin: 0 auto;display: block;}
.header_row .header_item.header-fill.last {justify-content: flex-end;}
.header_row .menu{list-style: none; padding: 0; column-gap: 30px; margin: 0; display: flex; justify-content: space-between; flex-wrap: wrap; width: 92%;}
.menu li{    color: rgba(159, 149, 107, 1); font-weight: 700; font-family: 'Roxborough-CF'; font-weight: 700;font-style: normal;transition-duration: 0.5s;text-transform: uppercase;line-height: 1.8;letter-spacing: 0.2em;font-size: 18px;}
.menu li a{color: rgba(159, 149, 107, 1); transition:0.5s ease all; text-decoration: none;}
.menu li a:hover{color: rgba(255,142,131,1); font-family: 'Prata';font-weight: 400;font-style: normal;}

.menu-btn {display: block; position: absolute; right: 15px; top: 15px; z-index: 3;  }
.header__nav-button, .toogle-menu { background: transparent;width: 27px;height: 18px; border: none;-webkit-appearance: none;-moz-appearance: none;appearance: none; display: block;   -webkit-transform: rotate(0deg);   -ms-transform: rotate(0deg);   transform: rotate(0deg); }
.header__nav-button:hover, .header__nav-button:focus { outline: none; }
.header__nav-button > span {background:rgba(255,142,131,1);display: block;position: absolute;height: 2px;width: 100%; left: 0;-webkit-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);-webkit-transition: 0.3s ease-in-out, background 0.2s cubic-bezier(0, 0, 0.58, 1);-o-transition: 0.3s ease-in-out, background 0.2s cubic-bezier(0, 0, 0.58, 1);transition: 0.3s ease-in-out, background 0.2s cubic-bezier(0, 0, 0.58, 1); }
.header__nav-button > span:nth-child(1) { top: 0px; }
.header__nav-button > span:nth-child(2) {top: 8px; }
.header__nav-button > span:nth-child(3) {top: 16px; }
.toogle-menu > span:nth-child(1) {top: 8px;-webkit-transform: rotate(135deg);-ms-transform: rotate(135deg);transform: rotate(135deg); }
.toogle-menu > span:nth-child(2) {opacity: 0;left: -60px; }
.toogle-menu > span:nth-child(3) {top: 8px; -webkit-transform: rotate(-135deg); -ms-transform: rotate(-135deg); transform: rotate(-135deg); }
.toogle-menu > span{background:rgba(255,142,131,1);}
.mobile_menu.open-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: inherit;
  z-index:1;
}

.mobile_menu {position: absolute;     pointer-events: none; opacity: 0; visibility: hidden; transition: 0.5s ease all; right: 0; top: 0; background: rgba(252,238,250,1); max-width: 480px; width: 100%;padding: 95px 31px 35px 49px;}
.mobile_menu .flex-m {display: flex;justify-content: space-between;}
.mobile_menu .left-part ul { list-style: none; padding: 0; margin: 0;}
.mobile_menu .left-part ul li { text-transform: uppercase;font-size: 26px; font-family: 'Roxborough-CF'; font-weight: 400;font-style: normal; margin-bottom: 15px;}
.mobile_menu .left-part ul li a {text-decoration: none;color: rgba(159, 149, 107, 1);transition: 0.5s ease all;}
.mobile_menu .left-part ul li a:hover {text-decoration: underline;}
.mobile_menu .right-part {display: flex;flex-direction: column;align-items: flex-end; row-gap: 10px;}
.start_link {background-color: rgba(255, 142, 131, 1);transition-duration: 0.5s;transition-property: background-color;color: rgba(245, 243, 243, 1);text-transform: uppercase; font-size: 26px;font-family: 'Roxborough CF';font-weight: 400;font-style: normal; transition: 0.5s ease all;text-decoration: none; padding: 0 7px; display: inline-block;}
.start_link:hover { color: rgba(248, 211, 242, 1);text-decoration: underline;}
.login_link {background-color: rgba(159, 149, 107, 1);display: inline-block;padding: 0 5px;color: rgba(245, 243, 243, 1);text-transform: uppercase;font-size: 26px; font-family: 'Roxborough-CF'; font-weight: 400; font-style: normal;transition: 0.5s ease all; text-decoration: none;}
.login_link:hover {color: rgba(248, 211, 242, 1);text-decoration: underline;}
.right-part ul.social-links {
  list-style: none;
  padding: 0; 
  margin: 0;margin-top: 22px;
}

.right-part ul.social-links li {
  width: 46px; margin-bottom: 8px;
  height: 46px;
}
.right-part ul.social-links li a{color: rgba(255, 142, 131, 1); display: block; transition: 0.5s ease all;}
.right-part ul.social-links li a svg{fill: currentColor;}
.right-part ul.social-links li a:hover{color:rgba(159, 149, 107, 1) ;}


.footer_wrap{      }
.grad_footer{background-image: linear-gradient(0deg, rgba(248, 211, 242, 1) 0%, rgba(246, 220, 212, 1) 100%);padding: 48px 0 1px;}
.footer_row{display: flex; align-items: center;  padding-bottom: 48px; gap:15px}
.footer_wrap p.dream{    color: rgba(54, 54, 54, 1);
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  font-family: "Roxborough-CF";
  font-weight: 600;
  font-style: normal;
  transition-duration: 0.5s;
  transition-property: color;  }
.footer_logo{max-width: 180px;}
.footer_logo a{display: block;}
.footer_logo  img{max-width: 100%; display: block;}
.footer_links{width: 100%;}
.footer_links ul{list-style: none;padding: 0; margin: 0; display: flex; align-items: center; justify-content: center; gap:6%}
.footer_links ul li{text-align: left;font-family: "Prata", serif;font-weight: 400;font-style: normal;text-transform: capitalize;line-height: 1.8;letter-spacing: 0.2em;font-size: 18px;}
.footer_links ul li a{color: rgba(54,54,54,1); transition-duration: 0.5s;  text-decoration: none; font-weight: 700}
.footer_links ul li a:hover{text-decoration: underline;}
.footer_links ul.column-2 {list-style: none;padding: 0; margin: 0; margin-left: 8%;display: flex;flex-wrap: wrap;max-width: 364px;}
.footer_links .column-2 li {text-align: left;font-family: "Prata", serif;font-weight: 400;font-style: normal;text-transform: uppercase;line-height: 1.8;letter-spacing: 0.2em;font-size: 18px;width: 50%;}
.footer_links .column-2 li a { color: rgba(159, 149, 107, 1); transition-duration: 0.5s;  text-decoration: none; font-weight: 700;}
.footer_links .column-2 li a:hover{text-decoration: underline;}
.footer_wrap .social-links{list-style: none; padding: 0; margin: 0;}
.footer_wrap .social-links li{    height: 40px; width: 40px; margin-bottom: 6px; transition-duration: 0.5s;transition-property: opacity;}
.footer_wrap .social-links li a{    color: rgba(255, 142, 131, 1);}
.footer_wrap .social-links li a svg{fill: currentColor;}
.footer_wrap .social-links li a:hover{color:rgba(131,57,108,1)}
.copyright_f{background: rgba(159,149,107,1); text-align: center; padding: 5px 16px;}
.copyright_f p{color: #fff;font-size: 14px; margin: 0; font-family: "Prata", serif;font-weight: 400;font-style: normal;}
.steps.result-screen1{padding-bottom: 85px;}
@media (max-width: 1400px){
.sliderContainer .slick-next{right: 35%;}
.sliderContainer .slick-prev{left: 35%;}

}
@media (max-width: 1150px){
.header_row .menu{column-gap: 18px; width: 98%;}
.menu li{font-size: 16px;}
.footer_links ul{gap:2.5%}
}
@media (max-width: 992px){
.rich-move-wrap h3:after{margin-left: -30%;}
.header_row .header_item.header-fill{display: none;}
.mobile_menu .flex-m{flex-wrap: wrap;}
.mobile_menu{padding-left: 15px; padding-right: 15px;}
.left-part{width: 100%;}
.mobile_menu .right-part {
  width: 100%;
  align-items: flex-start;
  margin-top: 15px;
}
.right-part ul.social-links{display: flex; gap: 10px;}
.menu-btn{position: relative; left: auto; top: auto;}
.mobile_menu{max-width: 100%; transform: translateY(-100%);}
.mobile_menu.open-menu{max-width: 100%; transform: translateY(0%);}
.header_row .header-logo {
  justify-content: center;
  position: relative;
  z-index: 3;
}
.header_row .header-logo a img{max-width: 71px;}
.footer_row{flex-wrap: wrap;}
.footer_logo{margin: 0 auto;}
.footer_links ul{flex-direction: column; gap: 15px;}
.footer_row{padding-bottom: 25px;}
}
@media (max-width: 767px){
  .video-modal .container{padding:26px 10px; width:95%;}
  .calculator-wrap{padding-bottom: 64px;}
  .step-1, .step-11{padding-top: 64px;}
  .step-head h2{ font-size: 32px; margin-bottom: 24px;}
  .step-head p{font-size: 16px;}

  .step-1 .step-head{margin-bottom: 48px;}
  .step-2{padding-top: 32px;}
  .input-form h3{font-size: 26px; margin-bottom: 24px;}
  .calculator-wrap{padding-top: 32px;}
  .input-form{margin-bottom: 32px;}
  .name-response{padding-top: 24px;}
  .name-response h3{font-size: 26px; line-height: 120%; margin-bottom: 16px;}
  .name-response h5{font-size: 24px; line-height: 160%; margin-bottom: 30px;}
  .name-response p{font-size: 16px;}
  .name-response .btn{margin-top: 20px;}
  .email-step{padding-top: 24px;}
  .team-expenses{padding-top: 24px;}
  .team-expenses h2{font-size: 32px; margin-bottom: 30px;}
  .team-expenses .h2{font-size: 26px; line-height: 150%; margin-bottom: 16px;}
  .team-expenses .input-form{margin-top: 30px;}
  .team-expenses .input-form h3{font-size: 20px; line-height: 160%; margin-bottom: 16px;}
  .show-767{display: block !important; }
  .team-expenses .show-767{text-align: center;}
  .team-expenses .show-767 .btn{padding: 14px 16px; min-width: auto; font-family: "Roxborough-CF";font-size: 14px; font-style: normal;  font-weight: 700;line-height: normal;letter-spacing: 0.56px;}


  .final-video{padding-top:24px; text-align: center;}
.final-video h3{font-size: 26px; margin-bottom: 16px;}
.video-box{margin-bottom: 48px; background: rgba(244, 185, 197, 0.46); padding: 8px; box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.09);}
.video-box video{min-height: 304px; object-fit: cover;}
.final-video .btn{margin-top: 48px;}
.final-video h3~h3{margin-bottom: 0 ;}

.result-screen1{padding-top: 33px; min-height: 387px;}
.result-screen1 h2{font-size: 26px; margin-bottom: 48px;}
.result-screen1 h3{font-size: 32px; margin-bottom: 48px;}

.result-felling{padding-top: 32px;}
.result-felling h2{font-size: 26px; margin-bottom: 32px;}

.result-felling .v-box{padding: 24px 20px 48px;}
.result-felling .v-box p{font-size: 18px;}
.result-felling .v-box video{min-height: 304px; object-fit: cover;}
.result-felling .custom-radio-wrap{padding: 24px 20px;}
.result-felling .custom-radio-wrap h5{font-size: 20px;}
.custom-radio [type="radio"]:checked + label, .custom-radio [type="radio"]:not(:checked) + label{font-size: 14px;}
.custom-radio [type="radio"]:checked + label:before, .custom-radio [type="radio"]:not(:checked) + label:before{top: 0;}
.custom-radio [type="radio"]:checked + label:after, .custom-radio [type="radio"]:not(:checked) + label:after{top: 5px;}
.result-banner-row{flex-wrap: wrap; margin-top: 30px;}
.result-banner-heading{width: 100%;}
.result-banner-heading h1{font-size: 46px; text-align: center;}
.result-banner-content{width: 100%; margin-top: 20px;}
.result-banner-content p{font-size: 18px; margin-bottom: 25px;}
.result-banner-content h3{font-size: 24px;}
.result-banner-content h3~h3{margin-bottom: 25px;}

.money-calc-head h2{font-size: 26px;}
.money-calc-head p{font-size: 16px;}
.money-calc-head h2{font-size: 24px;}

.money-calc-head p{font-size: 14px;}
.money-calc-row{padding: 20px;}
.money-calc-head-row p{font-size: 16px;}

.fin-need-pers {
  width: 32%;
}
.fin-need-dollar {
  width: 32%;
  text-align: center;
}
.money-calc-fin-row p{font-size: 14px;}
.rich-move-head h2{font-size: 28px;}

.rich-move-wrap h3{font-size: 18px; text-align: center; max-width: 100%;}

.rich-move-wrap h3:before{height: 4px; margin-left: 0; max-width: 100%; margin: 32px 0; width: 100%;}

.rich-move-wrap h3:after{height: 4px; margin-left: 0; max-width: 100%; margin: 32px 0; width: 100%; }

.rich-move-head p{font-size: 16px; margin-bottom: 8px;}

.introducing-box h2{font-size: 34px;}

.introducing-box h4{font-size: 20px;}

.introducing-box p{font-size: 20px;}
.rich-move-head h2{font-size: 26px;}
.rich-move-wrap h3{font-size: 20px; max-width: 100%;}
.rich-move-wrap h3:before{height: 5px; margin-left: 0; max-width: 100%; width: 100%;}
.rich-move-wrap h3:after{display: block;width: 100%; max-width: 100%; }
.rich-move-head p{font-size: 14px;}
.introducing-box h2{font-size: 34px; margin-bottom: 16pxs;}
.introducing-box h4{font-size: 20px; margin-bottom: 16px;}
.introducing-box p{font-size: 20px;}
.video-modal{padding: 72px 10px 52px;width: 95%; }
.video-modal .close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}
.video-modal video{min-height: 304px; object-fit: cover;}
.result-banner-wrap .revenu{font-size: 14px;}

.result-banner-wrap{padding-bottom: 60px;}

.money-calc-wrap{padding: 48px 0;}

.money-calc-head-row p {

  font-size: 11px;

}

.money-calc-head-row{padding: 10px 0;}

.money-calc-head-row .fin-need p{max-width: 88px;}

.money-calc-fin-row{padding: 8px;}

.money-calc-fin-row .fin-need p{gap:2px}

.money-calc-fin-row p{font-size: 13px;}

.fin-need {

  width: 88%;

}

.fin-need-pers {

  min-width: 72px;

}

.fin-need-dollar {

  min-width: 85px;

}

.fin-need-equal {

  min-width: 24px;

}

.money-calc-fin-row.total p{font-size: 14px;}

.money-calc-fin-row.total .fin-need-pers p, .money-calc-fin-row.total .fin-need-dollar p{font-weight: 400;}

.money-calc-head{margin-bottom: 24px;}



.introducing-box{padding: 48px 24px; margin: 32px auto 22px;}

.introducing-wrap .btn{padding: 23px;}

.copyright p{font-size: 14px;}



.rich-move-wrap{padding-top: 48px;}

.rich-move-head{text-align: center;}

.here_how_row{flex-wrap: wrap;}

.here_how_wrap{padding-top: 0; padding-bottom: 0;}

.here_how_wrap h2{font-size: 28px; margin-bottom: 12px;}

.here_how_wrap .show-m{display: block !important;}

.here_how_wrap .hide-m{display: none !important;}

.here_how_head{width: 100%;}

.here_how_head img{margin: 0 auto; display: block;}

.here_how_video{width: 100%; margin-top: 32px;}

.email-step .input-form h3{margin-bottom: 32px;}

.email-step .input-form p{margin-top: 8px;}


/* New Design css Mobile*/

.desk-show{display: none !important;}
.mobile-show{display: block  !important;}
.welcome_row{padding-bottom: 26px;}
.welcome_content{width: 100%; text-align: center;}
.welcome_content p{font-size: 16px; margin-bottom: 24px;}
.welcome_content h3{font-size: 28px; margin-bottom: 26px;}
.welcome_content img{margin-bottom: 0;}
.step-one-start{padding: 24px 16px 64px;}
.step-one-start p{font-size: 20px; padding-bottom: 24px; margin: 0; border-bottom: 1px solid #000; }
.step-one-start img{margin: 24px auto;}
.step-1 .step-one-start .btn{margin-top: 0;}
.wondering_wrap{padding: 0;}
.wondering_box{padding: 48px 16px; border-radius: 9px;background: rgba(255, 255, 255, 0.59); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06); backdrop-filter: blur(20px);}
.wondring_head h2{font-size: 26px;}
.wondering_wrap::before{display: none;}
.wondring_head h3{font-size: 26px;}
.wondering_number_head{width: 100%; text-align: center;}
.wondering_number_row{width: 100%; margin-top: 32px; row-gap: 16px;}
.wondering_number_head h3{font-size: 20px;}
.wondering_number_box{width: 100%;}
.wondering_number_box h4{font-size: 16px;}
.wondering_number_box p{font-size: 16px;}
.wondering_footer{padding-top: 32px;}
.wondering_footer p{font-size: 16px;}
.Chances_wrap{margin-bottom: 0;}
.Chances_row{flex-wrap: wrap;}
.single_chances{width: 100%;}
.single_chances h2::before{max-width: 100%; width: 100%;}
.single_chances h2{font-size: 26px;}
.single_chances p{font-size: 16px;}
.Chances_footer h2{font-size: 26px;}
.step_one_gathering{padding: 48px 16px;}
.step_one_gathering::before{display: none;}
.step_one_gathering h2{font-size: 26px;}
.step_one_gathering p{font-size: 16px;}
.team-expenses .h2{margin-bottom: 32px;}
.team-expenses-row{margin: 0 -16px; padding:24px 16px;}
.team-expenses-row {display: grid; grid-template-columns: repeat(1, 1fr);gap: 16px;}
.team-expenses-row > ul li{font-size: 16px;}
.team-expenses h2{font-size: 26px; margin-bottom: 32px;}
.team-expenses-notes-row {display: grid;grid-template-columns: repeat(1, 1fr); gap: 16px;}
.team-expenses-notes-row p{font-size: 16px;}
.team-expenses .input-form{margin-top: 0;}
.team-expenses .input-form p{font-size: 14px; margin-top: 0;}
.step-6 .team-expenses p, .step-7 .team-expenses p , .step-8 .team-expenses p{font-size: 16px;}
.team-expenses-row>ul>li~li{margin-top: 16px;}
.team-expenses.monthly-investment .input-form{margin-top: 0;}
.step-6 .team-expenses .input-form p, .step-7 .team-expenses .input-form p, .step-8 .team-expenses .input-form p{font-size: 14px; margin-top: 0;}
.step-7 .team-expenses-row { grid-template-columns: repeat(1, 1fr);}
.step-7 .team-expenses.monthly-investment .input-form {
  margin-top: 48px;
}
.step-9 .number_keys{padding: 48px 0;}
.step-9 .number_keys p{font-size: 20px;}
.step-9 .number_keys_box{padding: 16px;}
.number_keys_box h4{font-size: 20px;}
.number_keys_box li{font-size: 16px;}
.number_keys_box ol{margin: 0; width: 100%; padding-left: 20px;}
.step-9 .final-video h3{font-size: 20px;}
.result-screen1 h2{margin-top: 32px;}
.result-screen1 h3{margin-bottom: 24px;}
.result_con{padding: 16px;}
.result_con p{font-size: 18px;}
.result_con p span{font-size: 22px;}

.result-felling .step-container{padding-top: 32px;}
.result-felling h3{font-size: 32px; margin-bottom: 32px;}
.result-felling .v-box p{font-size: 20px;}
.custom-radio [type=radio]:checked + label, .custom-radio [type=radio]:not(:checked) + label{padding-left: 32px;}
.custom-radio [type=radio]:checked + label:before, .custom-radio [type=radio]:not(:checked) + label:before{width: 24px; height: 24px;}
.custom-radio [type=radio]:checked + label:after, .custom-radio [type=radio]:not(:checked) + label:after {
  top: 4px;
  width: 16px;
  height: 16px;
  left: 4px;
}
.money-calc-footer p{font-size: 16px;}
.introducing-wrap .btn{max-width: 289px;}
.sliderContainer{padding: 48px 0;}
.sliderContainer .item{padding: 0 16px;}
.sliderContainer .item .slideInner{padding: 66px 16px;}
.sliderContainer ul.slick-dots.slick-thumb {
  position: relative;
  bottom: auto;
  margin-top: 25px;
}
.sliderContainer .item .slideInner svg{top:22px}
.sliderContainer .slick-next{z-index:3; position: absolute; width: 40px; height: 40px;  right:0; left:auto; top:auto; bottom:-5px;  transform:none; top:auto; opacity:1;}
.sliderContainer .slick-prev{z-index:3; position: absolute; width: 40px; height: 40px; right:auto; left:0; top:auto; bottom:-5px;  opacity: 1; transform:none;}

.grad_footer{padding-top: 35px;}
.footer_logo{margin-bottom: 25px;}
.footer_row{flex-wrap: wrap; justify-content: center; row-gap: 0; padding-bottom: 15px;}
.footer_links{width: 100%; margin-bottom: 15px;}
.footer_links ul.column-2{max-width: 100%; margin-left: 0;}
// .footer_links ul li{margin-bottom: 12px;}
.footer_wrap .social-links {
  margin: 0;
  display: flex;
  gap: 15px;
}
.copyright_f span{display: none;}
.copyright_f p{color: #fff;}
.steps.result-screen1{padding-bottom: 32px;}
}




@media (max-width: 640px){
  .step-head h2{max-width: 300px; margin-left: auto; margin-right: auto; }
  .step-1 .btn{margin-top: 10px; width: 100%; }
  .result-screen1 button.btn {
    width: 100%;
  }
  .btn{font-size: 16px; padding: 15px;}
  .step-2 .btn{min-width: auto; width: 100%;}
  .result-felling .btn{width: 100%; display: block;}
  .name-response .btn, .email-step .btn{width: 100%;}
  .btn-group{justify-content: space-between; gap: 0;}
  .btn-group .btn{min-width: 140px;}
  .money-calc-row{padding: 0; box-shadow: none;}
  .money-calc-row {

    padding: 0;

    box-shadow: none;

    border-radius: 16px;

    border: 1px solid #FFF;

    background: rgba(255, 251, 252, 0.79);

    box-shadow: 0px 12px 23px 0px rgba(187, 108, 124, 0.08);

    backdrop-filter: blur(3.5px);

    padding: 24px 8px;

}

  .result-banner-heading h1{font-size: 40px;}
    .custom-radio-wrap .d-flex {
        display: flex;
        gap: 24px;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;
    }
    .back-tooltip{max-width: 253px;}
    .back-tooltip p{font-size: 14px;}
}

.invalid-feedback {
  display: flex;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}

.span-control{
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

/* New css*/
.steps{padding: 0;}
.calculator-wrap{padding: 0;}
p strong{font-weight: 900;}
.m-step-name-wrapper{padding: 48px 0; position: relative;}
.m-step-name-wrapper::before{content: ""; width: 67%; height: 100%; background: url(../src/img/hero-shadow.svg) no-repeat center right; background-size:cover; position: absolute; top: 0; left: 0;}
.m-step-name-wrap{max-width: 1280px; margin:0 auto; padding: 0 36px; display: flex;justify-content: space-around;flex-direction: row-reverse;}
// .m-step-name-img {width: 49.5%;}
.m-step-name-img {
    width: 49.5%;
    object-fit: cover;
    background: url(../src/img/business-woman.jpg);
    background-size: cover;
    background-position: 64%;
    border-radius: 10px;
}
// .m-step-name-img {width: 49.5%;}
.m-step-name-img img{opacity: 0;}
.m-step-name-contant {width: 49.5%; background: rgba(255,255,255,0.38); border: 1px solid #fff; border-radius: 10px; padding: 41px 36px 39px; position: relative;}

// .m-step-name-contant::before{content: ""; width: 109.5%; height: 104%; background: url(../src/img/hero-bg.svg) no-repeat center ; background-size: cover; position: absolute; left: 0; top:-9px;}
.m-step-name-contant>div{position: relative;}
.m-step-name-contant h2{color:  #212022; margin-bottom: 16px; font-family: 'Roxborough-CF';font-size: 64px;font-style: normal;font-weight: 700; line-height: normal;}
.m-step-name-contant p{color:  #212022; max-width: 507px; font-size: 24px; font-style: normal;font-weight: 500; line-height: 160%; margin-bottom: 64px;}
.m-step-name-contant h3{font-family: 'Roxborough-CF'; margin: 0; margin-bottom: 24px; font-size: 30px; font-style: normal;font-weight: 700;line-height: normal;}
.m-step-name-contant .form-control{border: 1px solid #E2E2E2; color: #636363; font-family: "Mulish", sans-serif;font-size: 16px; padding: 19px 20px; background: #FFF; max-width: 473px; width: 100%; display: block;}
.m-step-name-contant .form-control:focus{outline: none ;}
.m-step-name-contant .btn{margin-top: 0; min-width: 278px; padding: 23px;}
.m-step-name-contant .step-col{margin-bottom: 24px;}

.m-step2-eamil {padding: 15px 16px 75px; background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(182deg, rgba(244, 185, 197, 0.20) -48.51%, rgba(255, 255, 255, 0.20) 98.71%);}
.m-step2-eamil .step2-containers{padding-top: 50px;}
.m-step2-eamil .container {
  text-align: left;
}
.m-step2-eamil h2{color:#212022;text-align: center;font-family: "Roxborough-CF";font-size: 64px;font-style: normal;font-weight: 700;line-height: normal; margin-bottom: 32px;}
.m-step2-eamil h6{color:#212022;text-align: center;     font-family: "Mulish", sans-serif;font-size: 32px;font-style: normal;font-weight: 500;line-height: 160%; margin-bottom: 21px;}
.m-step2-eamil .input-form{max-width: 564px; margin: 0 auto;}
.m-step2-eamil .input-form .form-control{border: 1px solid #E2E2E2; color: #636363; font-family: "Mulish", sans-serif;font-size: 16px; padding: 19px 20px 19px 32px; background: #FFF; width: 100%; display: block; margin-bottom: 0;}
.m-step2-eamil .input-form p{color:#212022;font-size: 16px;     font-family: "Mulish", sans-serif; margin: 0; margin-bottom: 32px; text-align: left; font-style: normal; font-weight: 400;line-height: 160%;}
.m-step2-eamil .input-form p a{color: currentColor; text-decoration: underline;}
.m-step2-eamil img {max-width: 866px;width: 100%;margin: 0 auto 19px;display: block;}

.m-intro-wrapper{padding: 0;}
.m-intro-wrap {display: flex; flex-wrap: wrap;}
.m-intro-img { width: 50%;}
.m-intro-img img{width: 100%; height: 100%; object-fit: cover;}
.m-intro-content {width: 50%;display: flex;align-items: center; padding: 32px 16px; position: relative;}
.m-intro-content a.back-step {
  position: absolute;
  top: 10px;
}
.m-intro-content>div {max-width: 516px;margin: 0 auto;text-align: center;}
.m-intro-content h2{color:#212022; margin-bottom: 32px; font-family: "Roxborough-CF"; font-size: 64px;font-style: normal;font-weight: 700;line-height: normal;}
.m-intro-content p{color:#212022; max-width: 516px; margin-left: auto;margin-right: auto; font-family: "Mulish", sans-serif;font-size: 24px;font-style: normal;font-weight: 500;line-height: 160%; margin-bottom: 42px;}

.m-await-wrapper{padding: 15px 0 75px; position: relative; }
.m-await-wrapper::before{content: ""; height: 604px; width: 47%; background: url(../src/img/await-before.svg) no-repeat center right; background-size: cover; position: absolute; bottom: 0; left: 0;}
.m-await-wrapper::after{content: ""; height: 718px; width: 47%; background: url(../src/img/await-after.svg) no-repeat bottom left; background-size: cover; position: absolute; top: 0; right: 0;}

.m-await-head{text-align: center; padding-top: 50px; margin-bottom: 40px; position: relative;}
.m-await-head h2{color:#212022; margin-bottom: 32px; font-family: "Roxborough-CF"; font-size: 64px;font-style: normal;font-weight: 700;line-height: normal;}
.m-await-head p{color:#212022; text-transform: uppercase; max-width: 718px; margin-left: auto;margin-right: auto; font-family: "Mulish", sans-serif;font-size: 24px;font-style: normal;font-weight: 500;line-height: 160%; margin-bottom: 0;}
.m-await-head p strong{font-weight: 900;}
.m-await-box {max-width: 813px;margin: 0 auto; position: relative; padding: 48px;border-radius: 16px;border: 1px solid #FFF;background: rgba(255, 255, 255, 0.21);box-shadow: 0px 12px 23px 0px rgba(51, 1, 11, 0.12);backdrop-filter: blur(5px);text-align: center;}
.m-await-box p{color:#212022; margin-bottom: 32px; font-family: "Mulish", sans-serif;font-size: 32px;font-style: normal;font-weight: 500;line-height: 160%;}

.m-mvr-wrap{padding: 75px 0 83px; position: relative;}
.m-mvr-wrap::before{content: ""; width: 502px; height: 859px; background: url(../src/img/mvr-before.svg) no-repeat center right; background-size:cover; position: absolute; top: 21%; left: 0; }
.m-mvr-wrap::after{content: ""; width: 690px; height: 785px; background: url(../src/img/mvr-after.svg) no-repeat center right; background-size:cover; position: absolute; top: 42%; right: 0; }
.mvr_head{text-align: center; margin-bottom: 48px; position: relative;}
.mvr_head h2{color: #212022; margin-bottom: 16px;font-family: "Roxborough-CF"; font-size: 64px; font-style: normal; font-weight: 700;line-height: normal;}
.mvr_head h6{color: #212022; font-size: 32px; font-family: "Mulish", sans-serif; margin-bottom: 32px; font-style: normal;font-weight: 700;line-height: 160%; }
.mvr_head p{max-width: 995px; margin: 0 auto; color: #212022;font-size: 24px;font-style: normal;font-weight: 500; line-height: 160%;}

.mvr_calc_row{display: grid; position: relative; z-index: 2; margin-bottom: 40px; grid-template-columns: repeat(2, 1fr); column-gap: 48px; row-gap: 32px;}
.single_calc{border-radius: 16px; padding: 48px 24px; background: #FCEFF2;box-shadow: 0px 12px 23px 0px rgba(51, 1, 11, 0.12);}
.single_calc .form-control{border: 1px solid #E2E2E2; color: #636363; font-family: "Mulish", sans-serif;font-size: 16px; padding: 19px 20px; background: #FFF; width: 100%; display: block; margin-bottom: 0;}
.single_calc .form-control:focus{outline: none;}
.single_calc h4{color: #212022;font-family: "Mulish", sans-serif;font-size: 28px; font-style: normal; font-weight: 500;line-height: 160%; margin-bottom: 22px;}
.single_calc h4 a{color: #ED8A8C; text-transform: uppercase; text-decoration: none; font-size: 20px;font-style: normal;font-weight: 500;line-height: 160%;}
.light-cream{border-radius: 16px;background: #FCF9EF;}
.light-green{background: #F5FCEF;}
.light-gray{background: #F4F4F4;}
.single_calc .img{margin-bottom: 40px;}
.single_calc .img img{margin: 0 auto; display: block;}
.m-mvr-wrap .btn{font-size: 16px; letter-spacing: 0.64px;}

.mvr-help-wrap{padding-top: 114px; background: url(../src/img/help-bg.svg) no-repeat center; background-size: cover; padding-bottom: 125px; text-align: center; position: relative;}
.mvr-help-wrap h6{color:  #212022;text-align: center;font-family: "Roxborough-CF";font-size: 32px;font-style: normal;font-weight: 500;line-height: 120%; margin-bottom: 16px;}
.mvr-help-wrap h2{color: #212022;font-family: "Roxborough-CF";font-size: 60px;font-style: normal;font-weight: 400;line-height: 120%; max-width: 999px; margin: 0 auto;}
.mvr-help-wrap h2 a{color: var(--ed-8-a-8-c, #ED8A8C); font-weight: 700; text-decoration: underline;}
.modal-wrapper{background: rgba(255, 255, 255, 0.65);backdrop-filter: blur(10px); flex-direction: column;}
.video-modal video{max-width: 688px; display: block; margin: 0 auto;}

.close-icon {
  position: fixed; z-index: 2;
  right: 32px;
  top: 20px;
}
.modal-body{background: #FFF; max-width: 1218px; width: 95%; margin: 0 auto; margin-top: 49px; padding: 56px 0; box-shadow: 0px 15px 27px 0px rgba(0, 0, 0, 0.25);}
  .modal-body .team-expenses-row{border-bottom: none; padding-bottom: 0;}
  .modal-body .team-expenses-notes{padding-top: 0; padding-bottom: 32px;}
  .modal-body .btn-close{max-width: 327px; width: 100%; display: block; margin: 0 auto; font-size: 16px;
    font-style: normal;
    font-weight: 700; line-height: normal;letter-spacing: 0.64px; padding: 13px;}

.inactive{text-align: center;}
.inactive .h2{color: #212022;font-family: "Roxborough-CF"; margin-bottom: 32px; font-size: 40px; font-style: normal;font-weight: 700;line-height: 120%;}
.inactive p{color: var(--212022, #212022); font-family: "Mulish", sans-serif; font-size: 24px;font-style: normal; font-weight: 500; line-height: 160%; margin-bottom: 32px}
.m-step2-eamil .back-step img{margin-bottom: 0;}
.pt-15-d{padding-top: 15px;}
@media (max-width: 767px){
  .calculator-wrap{padding:0}
  .m-step2-eamil .input-form .form-control{padding-left:24px;}
.m-step-name-wrap{flex-wrap: wrap;}
.m-step-name-img{width: 100%;}
.m-step-name-contant{width: 100%; text-align: center;}
.m-step-name-contant::before{width: 100%; background: url(../src/img/hero-bg-m.svg) no-repeat center; background-size: cover;}
.m-step-name-contant h2{font-size: 32px;}
.m-step-name-contant p{font-size: 18px; line-height: 140%; margin-bottom: 16px;}
.m-step-name-contant h3{font-size: 26px; margin-bottom: 16px;}
.m-step-name-wrap{padding: 0 11px;}
.m-step-name-contant{padding: 64px 16px 26px; margin-top: 0%;}
.m-step-name-contant .form-control{margin: 0 auto; height:50px;}
.m-step-name-contant .step-col{margin-bottom: 16px;}

.pt-15-d{padding-top: 10px;}
.m-step2-eamil{padding: 10px 16px 48px;}
.m-step2-eamil .step2-containers{padding-top: 30px;}
.m-step2-eamil h2{font-size: 32px; margin-bottom: 24px;}
.m-step2-eamil h6{font-size: 22px; line-height: 140%; max-width: 272px; margin-left: auto; margin-right: auto;}
.m-step2-eamil img{margin-bottom: 35px;}
.m-step2-eamil .input-form .form-control{height: 50px;}
.Thanks-heading{margin-top:15px;}
// .m-step2-eamil .input-form p{display: none;}
.m-step2-eamil .input-form{margin-bottom: 32px;}

.m-intro-img { width: 100%;}
.m-intro-content {width: 100%; position: relative;}

.m-intro-content h2{font-size: 32px; margin-bottom: 24px;}
.m-intro-content p{font-size: 18px; line-height: 140%; margin-bottom: 32px;}
.m-intro-content .btn{width: 100%;}

.m-await-wrapper{padding: 10px 15px 73px;}
.m-await-head{margin-bottom: 32px; padding-top: 32px;}
.m-await-head h2{font-size: 32px; margin-bottom: 32px;}
.m-await-head p{font-size: 18px; line-height: 140%;}
.m-await-box{padding: 32px 16px;}
.m-await-box p{font-size: 22px; line-height: 140%; margin-bottom: 24px;}
.m-await-box .btn{width: 100%;}
.m-await-wrapper::after{width: 272px; height: 448px; background: url(../src/img/await-after-m.svg) no-repeat left; top:0}
.m-await-wrapper::before{width: 301px; height: 376px; background: url(../src/img/await-before-m.svg) no-repeat right; }

.m-mvr-wrap{padding: 53px 0;}
.mvr_head{margin-bottom: 24px;}
.mvr_head h2{font-size: 32px; margin: 16px;}
.mvr_head h6{font-size: 22px; line-height: 140%; margin-bottom: 24px;}
.mvr_head p{font-size: 18px; line-height: 140%;}
.mvr_calc_row { grid-template-columns: repeat(1, 1fr); margin-bottom: 32px;}
.single_calc{padding: 24px 16px; border-radius: 12px;}
.single_calc .img img{max-width: 217px;}
.single_calc .img{margin-bottom: 24px;}
.single_calc h4{font-size: 18px; line-height: 140%; margin-bottom: 5px;}
.single_calc h4 a{display: block; font-size: 12px; line-height: 160%;}
.single_calc .form-control{height: 50px;}
.m-mvr-wrap .btn{width: 100%;}
.mvr-help-wrap{padding-top: 104px; padding-bottom: 103px;}
.mvr-help-wrap h6{font-size: 24px; margin-bottom: 17px; line-height: 120%;}
.mvr-help-wrap h2{font-size: 30px;}
.m-mvr-wrap::before{width: 301px;height: 376px;background: url(../src/img/await-before-m.svg) no-repeat right; top:9%}
.m-mvr-wrap::after{width: 272px; content: ""; display: block; position: absolute; right: 0; height: 448px; background: url(../src/img/await-after-m.svg) no-repeat left; top:0}

.modal-body{padding: 32px 16px;}
.modal-body .team-expenses{padding-top: 0; padding-bottom: 20px;}
.modal-body .team-expenses-notes{margin-top: 70px;}
.modal-body .team-expenses-notes-row{gap: 32px;}
.modal-body .team-expenses-notes{padding-bottom: 48px;}
.modal-body .btn{width: 100%; max-width: 100%;}
.close-icon {
  position: fixed; z-index: 2;
  right: 11px;
  top: 20px;
}

.inactive .h2{font-size: 26px; line-height: 150%; margin-bottom: 24px; }
.inactive p{font-size: 16px; line-height: 160%; margin-bottom: 24px;}
}